<template>
  <section class="invoice-add-wrapper">
 
    <b-row class="invoice-add">
      <b-col cols="12" xl="9" md="8">
       
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                    </div>
                    <div>Address</div>
                    <div v-html="this.officeAddress"></div>

                    <p class="card-text mb-0">{{ this.officeEmail }}</p>
                    <p class="card-text mb-0">{{ this.officeMobile }}</p>
                  </div>

                  <div class="invoice-number-date mt-md-0 mt-2">
                    
                    <div
                      class="d-flex align-items-center justify-content-md-end mb-1"
                    >
                      <h4 class="invoice-title">Invoice</h4>
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group disabled"
                      >
                        
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceId"
                          disabled
                        />
                      </b-input-group>
                    </div>

                    <div class="d-flex align-items-center mb-1">
                      <span class="title">Invoice Date: </span>

                      <b-form-group>
                        
                          <b-form-input
                            v-model="invoiceDate"
                            class="form-control invoice-edit-input"
                            disabled
                          />
                      </b-form-group>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title"> Payment Date: </span>
                      <b-form-group>
                        
                          <b-form-input
                            v-model="paymentDate"
                            class="form-control invoice-edit-input"
                            disabled
                          />
                          
                      </b-form-group>
                    </div>
                  </div>
                  </div>
              </b-card-body>

              <hr class="invoice-spacing" />
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h5 class="mb-30">Invoice To:</h5>
                    <h6 class="mb-25">
                      {{
                        sellerName
                      }}
                    </h6>
                    <p class="card-text mb-25">
                      Email: 
                      {{
                        sellerEmail
                      }}
                    </p>
                    <p class="card-text mb-25">
                      Contact No: 
                      {{
                        sellerContact
                      }}
                    </p>
                    
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div>
                      <h6 class="mb-30">Payment Details:</h6>
                      <table>
                        <tbody>
                          <div>
                            <tr>
                              <td class="pr-1">Payment Method:</td>
                              <td>{{ paymentMethod }}</td>
                            </tr>
                            <!-- <tr>
                              <td>{{ paymentDetails }}</td>
                            </tr> -->
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>

                
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <b-col cols="12">
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <b-col cols="12" lg="5"> Item </b-col>
                          <b-col cols="12" lg="3"> Amount </b-col>
                          <b-col cols="12" lg="2"> Qty </b-col>
                          <b-col cols="12" lg="2"> Price </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="5">

                            <span class="font-weight-bold">Targeted Link: </span>
                            {{ targetedLink }}

                            <br>
                            
                            <span class="font-weight-bold">Seller website: </span>
                            {{ sellerWebsite }}
                          </b-col>
                          <b-col cols="12" lg="3">
                            {{ costPrice }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            {{ item.qty }}
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Price</label>
                            <p class="mb-1">
                              {{ costPrice * item.qty }} {{ currency }}
                            </p>
                          </b-col>
                         
                        </b-row>

                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="
                              invoiceData.items.length > 1
                                ? removeItem(index)
                                : null
                            "
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
               
              </b-card-body>

              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper custom-width">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Sub Total:</p>
                        <p class="invoice-total-amount">
                          {{ subTotalAmount }} {{ currency }}
                        </p>
                      </div>
                      <!-- <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <b-form-checkbox
                            v-model="vatTaxSelected"
                            @change="handleCheckboxClick"
                          >
                            <b>Include Vat Tax</b>
                          </b-form-checkbox>
                        </p>
                      </div> -->
                      <!-- <div class="invoice-total-item">
                        <p class="invoice-total-title">Vat:</p>

                        <b-form-group>
                          <ValidationProvider
                            name="vat amount"
                            v-slot="{ errors }"
                            vid="vat_amount"
                            rules="required|min_value:0"
                          >
                            <b-input-group :append="currency">
                              <b-form-input v-model="vatAmount" type="number" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Tax:</p>

                        <b-form-group>
                          <ValidationProvider
                            name="tax amount"
                            v-slot="{ errors }"
                            vid="tax_amount"
                            rules="required|min_value:0"
                          >
                            <b-input-group :append="currency">
                              <b-form-input v-model="taxAmount" type="number" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </div> -->

                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Grand Total:</p>
                        <p class="invoice-total-amount">
                          {{ grandTotalAmount }} {{ currency }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <!-- Note -->
              <!-- <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: </span>
                <b-form-textarea v-model="remarks" />
              </b-card-body> -->
            </b-card>
          
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            v-on:click="printInvoice"
          >
            Print
          </b-button>

        </b-card>
      </b-col>
    </b-row>

    <!-- Printed Invoice  -->
    <div id="content" style="display: none; flex-direction: column; width: 100%; padding: 20px; font-family: Arial, sans-serif;">
      <!-- Header -->
      <header style="display: flex; justify-content: space-between; margin-bottom: 20px;">
        <!-- Header: Left Content -->
        <div>
          <div class="logo-wrapper">
            <logo />
          </div>
          <address style="margin: 0;">
            <p>Address: {{ officeAddress }}</p>
            <p>Email: {{ officeEmail }}</p>
            <p>Contact: {{ officeMobile }}</p>
          </address>
        </div>
        
        <!-- Header: Right Content -->
        <div style="text-align: right;">
          <p style="margin: 0; font-weight: bold;">Invoice: {{ invoiceId }}</p>
          <p style="margin: 0; font-weight: bold;">Date Issued: {{ invoiceDate }}</p>
          <p style="margin: 0; font-weight: bold;">Payment Date: {{ paymentDate }}</p>
        </div>
      </header>

      <hr style="border: 1px solid #ddd; margin: 20px 0;" />

      <!-- Invoice Client & Payment Details -->
      <section style="display: flex; justify-content: space-between; margin-bottom: 20px;">
        <!-- Invoice Client: Left Content -->
        <div>
          <h6 style="margin-bottom: 5px;">Invoice To:</h6>
          <p style="margin: 0; font-weight: bold;">{{ sellerName }}</p>
          <p style="margin: 0;">Email: {{ sellerEmail }}</p>
          <p style="margin: 0;">Contact No: {{ sellerContact }}</p>
        </div>

        <!-- Payment Details: Right Content -->
        <div style="text-align: right;">
          <h6 style="margin-bottom: 5px;">Payment Details:</h6>
          <table style="text-align: left;">
            <tr>
              <td style="padding-right: 10px;">Payment Method:</td>
              <td>{{ paymentMethod }}</td>
            </tr>
          </table>
        </div>
      </section>

      <!-- Invoice Items Table -->
      <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <thead>
          <tr>
            <th style="padding: 8px; border: 1px solid #ddd;">Item</th>
            <th style="padding: 8px; border: 1px solid #ddd;">Cost</th>
            <th style="padding: 8px; border: 1px solid #ddd;">Qty</th>
            <th style="padding: 8px; border: 1px solid #ddd;">Price</th>
          </tr>
        </thead>
        <tbody>
          <!-- Assuming `invoiceData.items` has details -->
          <tr v-for="item in invoiceData.items" :key="item.id">
            <td style="padding: 8px; border: 1px solid #ddd;">
              Targeted Link: {{ targetedLink }}
              <br>
              Seller website: {{ sellerWebsite }}
            </td>
            <td style="padding: 8px; border: 1px solid #ddd;">{{ costPrice }}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">{{ item.qty }}</td>
            <td style="padding: 8px; border: 1px solid #ddd;">{{ costPrice * item.qty }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Invoice Totals -->
      <div style="display: flex; justify-content: flex-end;">
        <table style="text-align: left;">
          <tr>
            <td style="padding-right: 10px; font-weight: bold;">Subtotal:</td>
            <td>{{ subTotalAmount }} {{ currency }}</td>
          </tr>
          <!-- <tr>
            <td style="padding-right: 10px; font-weight: bold;">VAT:</td>
            <td>{{ vatAmount }} {{ currency }}</td>
          </tr>
          <tr>
            <td style="padding-right: 10px; font-weight: bold;">TAX:</td>
            <td>{{ taxAmount }} {{ currency }}</td>
          </tr> -->
          <tr>
            <td colspan="2"><hr style="border: 1px solid #ddd; margin: 10px 0;" /></td>
          </tr>
          <tr>
            <td style="padding-right: 10px; font-weight: bold;">Grand Total:</td>
            <td style="font-weight: bold;">{{ grandTotalAmount }} {{ currency }}</td>
          </tr>
        </table>
      </div>
    </div>



  </section>
</template>
  
  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BCardBody,
    BCardText,
    VBToggle,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTableLite,
  } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, max } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { mapGetters } from "vuex";
  import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
  import flatPickr from "vue-flatpickr-component";
  import Logo from "@core/layouts/components/Logo.vue";
  import {
    SALARY_POLICY_CREATE,
    SALARY_POLICY_EDIT,
    SALARY_POLICY_DELETE,
    SALARY_POLICY_SHOW,
  } from "@/helpers/permissionsConstant";
  
  export default {
    name: "OutreachInvoiceDetailsView",
    components: {
      UserAssignPolicyForm,
      BForm,
      BButton,
      BCard,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      ValidationProvider,
      ValidationObserver,
      BModal,
      BSpinner,
      BRow,
      BCol,
      VBTooltip,
      BCardBody,
      BCardText,
      VBToggle,
      BLink,
      Logo,
      BInputGroup,
      BInputGroupPrepend,
      flatPickr,
      BFormTextarea,
      BTableLite,


    },
    directives: {
      "b-tooltip": VBTooltip,
    'b-toggle': VBToggle,

      Ripple,
    },
    data() {
      return {
        
        clientAddress: "",
        invoiceData: {

        items: [
          {
            itemTitle: "",
            cost: 0,
            qty: 1,
            price: 0,
            description: "",
          },
        ],
      },

      // data
      officeAddress: "",
      officeEmail: "",
      officeMobile: "",

      sellerName: "",
      sellerEmail: "",
      sellerContact: "",

      invoiceId: "",
      invoiceDate: "",
      paymentDate: "",

      targetedLink: "",
      sellerWebsite: "",
      costPrice: "",
      currency: "",

      subTotalAmount: 0,

      vatAmount: 0,
      taxAmount: 0,

      paymentMethod: "",
      paymentDetails: "",
      


       
      };
    },
  
    computed: {
      ...mapGetters({
        permissions: "userModule/getPermissions",
      }),
      statusVariant() {
        const statusColor = {
          true: "light-success",
          false: "light-danger",
        };
  
        return (status) => statusColor[status];
      },

      grandTotalAmount() {
        return this.subTotalAmount + + this.vatAmount + + this.taxAmount;
    },
    },
  
    async created() {
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  
    methods: {

      formatDateTime(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
      },

    async printInvoice() {
      const printSection = document.getElementById('content');
      // const originalContent = document.body.innerHTML;

      // Create a new window for printing
      const newWindow = window.open('', '_blank', 'width=600,height=400');
      newWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              /* Add any styles you want for the printed content here */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
            </style>
          </head>
          <body>
            ${printSection.innerHTML}
          </body>
        </html>
      `);
      newWindow.document.close(); // Close the document
      newWindow.print(); // Trigger the print dialog
    },
      async getOutreachInvoiceItems() {
        const invoiceId = this.$route.params.id;
        return await this.$api.get(`api/outreach-invoices/${invoiceId}`);
      },
      async getSetting(params) {
        return await this.$api.get("/api/settings", {
          params: {
            select: params.select,
          },
        });
      },
  
      async loadItems() {
        try {
         
          const [getSetting, outreachInvoiceDetails ] = await Promise.all([
          this.getSetting({
            select: "account_id,address,email,mobile",
          }),
          this.getOutreachInvoiceItems(),
        ]);

          this.officeAddress = getSetting?.data?.data?.address;
          this.officeEmail = getSetting?.data?.data?.email;
          this.officeMobile = getSetting?.data?.data?.mobile;
  
          const data = outreachInvoiceDetails?.data?.data;

          this.sellerName = data?.seller?.name ;
          this.sellerEmail = data?.seller?.email ;
          this.sellerContact = data?.seller?.contact_no;
          this.invoiceId = data?.invoice_number ;
          this.invoiceDate = this.formatDateTime(data?.invoice_date) ;
          this.paymentDate = this.formatDateTime(data?.payment_date) ;

          this.paymentMethod = data?.payment_method_text ;
          this.paymentDetails = data?.payment_details ;

          this.targetedLink = data?.backlink?.targeted_url ;
          this.sellerWebsite = data?.seller?.website ;
          this.costPrice = data?.backlink?.cost_price ;
          this.currency = data?.backlink?.cost_price_currency;

          this.subTotalAmount = this.costPrice;

        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "BellIcon",
              variant: "warning",
              text: error?.response?.data?.message,
            },
          });
        }
      },
  
     
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
 @import "~@core/scss/base/pages/app-invoice.scss";

 .form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.custom-width {
  max-width: 25rem !important;
}
  </style>
  
  